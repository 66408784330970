import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f56a6a",
    },
    secondary: {
      main: "#3d4449",
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h1: {
      fontFamily: '"Roboto Slab", serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Roboto Slab", serif',
      fontWeight: 700,
    },
    body1: {
      color: "#7f888f",
    },
  },
});

export default theme;
